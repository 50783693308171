<template>
	<el-scrollbar class="uu-box">
		<div class="t-page">
			<div class="t-page-head">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">{{$t('seller.breadcrumb.home')}}</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ name: 'teachingList' }">{{pageInfo.cate_name}}</el-breadcrumb-item>
					<el-breadcrumb-item>{{pageInfo.title}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="t-page-wrap">
				<div class="page-title">
					<h2>{{pageInfo.title}}</h2>
					<p>{{$t('seller.article.updateTime')}}：{{ pageInfo.create_time | parseTime() }}</p>
				</div>
				<div v-if="pageInfo.file_type !== 0" class="page-main">
					<iframe :src="pageInfo.file_url" width="100%"></iframe>
				</div>
				<div v-else class="page-main" v-html="pageInfo.content">
				</div>
				<div class="page-bottom">
					<div class="item-link">
						<router-link v-if="prevPage.toString()" :to="{ name: 'teachingInfo', params: { id: prevPage.article_id } }"><i class="el-icon-back"></i>{{$t('seller.pagination.prevChapter')}}：{{ prevPage.title }}</router-link>
						<a v-else href="javascript:void(0);"><i class="el-icon-back"></i>{{$t('seller.pagination.prevChapter')}}：{{$t('seller.tag.not')}}</a>
					</div>
					<div class="item-link">
						<router-link v-if="nextPage.toString()" :to="{ name: 'teachingInfo', params: { id: nextPage.article_id } }">{{$t('seller.pagination.nextChapter')}}：{{ nextPage.title }}<i class="el-icon-right"></i></router-link>
						<a v-else href="javascript:void(0);">{{$t('seller.pagination.nextChapter')}}：{{$t('seller.tag.not')}}<i class="el-icon-right"></i></a>
					</div>
				</div>
				<div class="page-footer">
					<h2>{{$t('seller.article.recommend')}}</h2>
					<ul>
						<li v-for="(item,index) in recommendPage" :key="index">
							<router-link :to="{ name: 'teachingInfo', params: { id: item.article_id } }" class="item-link">{{ item.title }}</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { articleInfo, fetchArticleRecommend } from "@/api/seller/assess"
	export default {
		data() {
			return {
				pageInfo: {},
				prevPage: {},
				nextPage: {},
				recommendPage: []
			}
		},
		mounted() {
			this.getPageData();
		},
		methods: {
			async getPageData() {
				try {
					const { data } = await articleInfo({article_id: this.$route.params.id})
					this.pageInfo = data
					this.prevPage = data.pre_article || {}
					this.nextPage = data.next_article || {}
					const recommend = await fetchArticleRecommend()
					this.recommendPage = recommend.data
				} catch (error) {
					this.$router.go(-1)
				}
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 36px;

		.t-page-head {
			margin-bottom: 12px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
			::v-deep .el-breadcrumb {
				.el-breadcrumb__inner {
					&.is-link {
						color: $--seller-primary;
					}
				}
			}
		}

		.t-page-wrap {
			.page-title {
				h2 {
					line-height: 45px;
					font-size: 32px;
					color: #373d41;
					margin-bottom: 6px;
				}
				p {
					color: #999;
					font-size: 12px;
					line-height: 24px;
				}
			}
			::v-deep .page-main {
				color: #333333;
				margin: 20px 0;
				font-size: 14px;
				line-height: 1.6;
				word-wrap: break-word;
				h1, h2, h3, h4, h5, h6 {
					font-weight: 700;
				}
				img {
					max-width: 100%;
				}
				iframe {
					height: 800px;
					border: none;
				}
				video {
					background-color: #111111;
				}
			}
			.page-bottom {
				padding: 23px 0;
				display: flex;
				justify-content: space-between;
				border-top: 1px solid #d7d8d9;
				.item-link {
					font-size: 14px;
					a {
						color: #333333;
						[class^=el-icon-] {
							font-weight: 600;
							color: #333333;
						}
						&:hover {
							color: $--seller-primary;
							[class^=el-icon-] {
								color: $--seller-primary;
							}
						}
						.el-icon-back {
							margin-right: 6px;
						}
						.el-icon-right {
							margin-left: 6px;
						}
					}
				}
			}
			.page-footer {
				padding: 35px;
				background-color: #f4f8ff;
				h2 {
					font-size: 18px;
					font-weight: 600;
					color: #333333;
				}
				ul {
					display: flex;
					flex-flow: wrap;
					margin-top: 20px;
					li {
						flex-shrink: 0;
						width: 25%;
						box-sizing: border-box;
						padding: 4px 0;
						margin-bottom: 4px;
						.item-link {
							color: #333333;
							font-size: 14px;
							line-height: 1.28571;
							&:before {
								content: "";
								width: 6px;
								height: 6px;
								border-radius: 50%;
								margin-right: 8px;
								display: inline-block;
								background-color: $--seller-primary;
							}
						}
					}
				}
			}
		}
	}
</style>
